import React from "react";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";

import { store } from "@redux/store";
import { Router } from "@routes/Router";

import "moment/locale/vi";
import "./less/index.less";
import vi_VN from "antd/lib/locale/vi_VN";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Root = () => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={vi_VN}>
        <Router />
      </ConfigProvider>
    </Provider>
  );
};

root.render(<Root />);
