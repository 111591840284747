const en = {
  translation: {
    common: {
      currentLang: "English",
      noData: "Không có dữ liệu",
    },
    tabs: {
      uploadFile: "Tải file",
      tranDetail: "Chi tiết file giao dịch",
      verify: "Xác thực",
      uploadFileCompleted: "Hoàn tất tải file",
    },
    buttons: {
      back: "Trở về",
      continue: "Tiếp tục",
      verify: "Xác thực",
      uploadNewFile: "Tải file mới",
      backToHome: "Trở về trang chủ",
      cancel: "Hủy",
      accept: "Đồng ý",
      ok: "Ok",
      delete: "Xóa",
      reset: "Làm mới",
      chooseFile: "Chọn tệp tin",
      all: "Tất cả",
      valid: "Hợp lệ",
      inValid: "Không hợp lệ",
      apply: "Áp dụng",
      goToUploadFile: "Đi đến tải file",
    },
    inputs: {
      form: {
        username: "Tên đăng nhập",
        mobile: "Số điện thoại",
        bulkTransferPurpose: "Mục đích chuyển lô",
      },
    },
    pages: {
      bulkTransfer: {
        loadBulkFile: "Tải file chuyển tiền",
        quicklyTransferMoney: "Chuyển tiền nhanh chóng tới nhiều tài khoản từ file Excel",
        dragFile: "Hoặc kéo thả tệp tin vào đây",
        formatFile: "File Excel định dạng .xls, xlsx, dung lượng không quá 1Mb",
        notice: "Lưu ý",
        noticePrompt:
          "Tính năng này chỉ dùng để load file giao dịch. Bạn cần đăng nhập MyVIB 2.0 để xác nhận chuyển tiền.",
        template: "Mẫu file",
        use: "Sử dụng đúng",
        provider: "đổ lô do VIB cung cấp.",
        numberOfTransferItems:
          "Số món trong file không quá {{maxItem}} món và không được trùng lặp (tài khoản, ngân hàng, số tiền, nội dung).",
        valid: "Hợp lệ",
        invalid: "Không hợp lệ",
        duplicateItems:
          "File chuyển tiền có chứa giao dịch trùng lặp (số tài khoản {{accountNo}}). Bạn vui lòng kiểm tra và thực hiện lại.",
        order: "STT",
        receiver: "Người nhận",
        accountNo: "Số tài khoản",
        amount: "Số tiền",
        content: "Nội dung",
        banking: "Ngân hàng",
        status: "Trạng thái",
        tranDetail: "Chi tiết file giao dịch",
        authentication: "Xác thực thông tin",
        verifyPrompt: "Vui lòng nhập Tên đăng nhập và Số điện thoại MyVIB 2.0",
        completedUploadFile: "Hoàn tất tải file giao dịch",
        totalTransaction: "Tổng số giao dịch",
        totalMoneyTransactionValid: "Tổng số tiền giao dịch hợp lệ",
        totalTransactionValid: "Tổng số giao dịch hợp lệ",
        totalAmountValid: "Tổng số tiền hợp lệ",
        transactionValid: "Giao dịch hợp lệ",
        transactionInvalid: "Giao dịch không hợp lệ",
        tutorialBelow: "Bạn có thể xem chi tiết lô và xác nhận chuyển tiền trên MyVIB 2.0 như hướng dẫn bên dưới.",
        scanQr: "Quét mã QR",
        screen: "trên màn hình bằng",
        camera: "máy ảnh của điện thoại",
        installApp: "có cài MyVIB 2.0",
        or: "Hoặc làm theo hướng dẫn",
        selectTransaction: "Chọn Giao dịch",
        chooseToTransfer: "Chọn Chuyển tiền theo lô",
        chooseGroup: "Chọn File và nhóm",
        chooseTransactionPending: "Chọn Giao dịch chờ xử lý",
        purposeTransfer: "Đặt tên giao dịch lô",
        fieldNamePurpose: "Đặt tên giúp dễ dàng theo dõi lô chuyển tiền trên ứng dụng MyVIB 2.0 ",
        noticePurposePrompt1: "Tên mục đích giúp dễ dàng theo dõi lô chuyển  tiền trên ứng dụng MyVIB 2.0",
        noticePurposePrompt2: "Tên mục đích không quá 35 ký tự và chỉ được nhập các ký tự đặc biệt ,.-+():\\_&?",
        enterOtpCode: "Nhập Mã OTP",
        otpHasBeenSend: "Mã OTP đã được gửi đến số điện thoại {{phone}} của bạn",
        expiredOtpIn: "Mã OTP  hiệu lực trong",
        second: "{{remainingTime}} giây",
        defaultValuePurpose: "Chuyển tiền lô",
        uploading: "Đang tải lên...",
        napasTransactionProcessing:
          "* Giao dịch sẽ được xử lý qua hình thức chuyển nhanh (Napas) với các ngân hàng hỗ trợ và số tiền giao dịch dưới 500 triệu đồng. Nếu không kết nối được với ngân hàng nhận qua hình thức chuyển tiền nhanh, giao dịch sẽ được tự động điều hướng qua chuyển thường.",
        signInApp: "Vui lòng đăng nhập MyVIB 2.0 để xác nhận giao dịch chuyển lô",
      },
      notFound: {
        pageNotFound: "Rất tiếc, không tìm thấy trang này",
        pageYouSearch: "Trang bạn đang tìm kiếm có thể đã bị xóa, thay đổi hoặc tạm thời không thể truy cập.",
      },
      maintenance: {
        system: "Nâng cấp hệ thống",
        tryAgain:
          "Trang myvib2.vib.com.vn đang trong quá trình bảo trì. Bạn vui lòng thử lại sau. Chúng tôi xin lỗi về sự bất tiện này.",
      },
    },
    breadcrumb: {
      digitalBanking: "Ngân hàng số",
      bulkTransfer: "Chuyển tiền theo lô",
    },
    footer: {
      copyRight: "© Bản quyền 2023 thuộc về Ngân Hàng Quốc Tế (VIB)",
    },
    messages: {
      error: {
        unknownError: "Có lỗi trong quá trình lấy thông tin giao dịch. Bạn vui lòng thử lại.",
        duplicateItems:
          "File chuyển tiền có chứa giao dịch trùng lặp (số tài khoản {{accountNo}}). Bạn vui lòng kiểm tra và thực hiện lại.",
        required: "Trường này không được để trống. Vui lòng kiểm tra lại.",
        phoneInValid: "Số điện thoại bạn nhập không đúng định dạng. Vui lòng thử lại.",
        userNameInvalid: "Tên đăng nhập bạn nhập không đúng định dạng. Vui lòng thử lại.",
        noRegisterOtp: "Bạn chưa đăng ký phương thức nhận OTP. Vui lòng liên hệ 18008180 để được hỗ trợ.",
        blockedSmartOtp: "Vui lòng kích hoạt lại Smart OTP để tiếp tục giao dịch.",
        purposeTransfer: "Tên mục đích không quá 35 ký tự và chỉ được nhập các ký tự đặc biệt ,.-+():\\_&?",
        FILE_NO: "Số thứ tự phải là số hoặc để trống.",
        FILE_FORMAT: "File lô phải là file Excel và dung lượng file không vượt quá {{size}}. Bạn vui lòng thử lại.",
        FILE_NAME:
          "Tên file không được chứa ký tự đặc biệt (ngoại trừ dấu gạch dưới, dấu ngoặc đơn, dấu gạch ngang, dấu chấm và dấu cách) và không vượt quá 35 ký tự. Bạn vui lòng thử lại.",
        FILE_ACCOUNT: "File chuyển tiền có chứa giao dịch trùng lặp (số tài khoản {{accountNo}}).",
        FILE_LABEL: "Tên dòng không được chứa ký tự đặc biệt và không vượt quá 160 ký tự. Bạn vui lòng thử lại.",
        FILE_MAX_COL: "Số cột trong file không vượt quá 08. Bạn vui lòng thử lại.",
        FILE_MIN_ROW: "File lô bao gồm ít nhất 02 món hợp lệ. Bạn vui lòng thử lại.",
        FILE_MAX_ROW: "Số món giao dịch trong file không vượt quá {{maxItem}}. Bạn vui lòng thử lại.",
        FILE_AMOUNT_FORMAT: "Số tiền giao dịch không đúng định dạng.",
        FILE_DES_FORMAT: "Nội dung giao dịch không được chứa ký tự đặc biệt ngoại trừ ,.-+():\\_&?.",
        FILE_DES_LENGTH: "Nội dung giao dịch không được vượt quá 120 ký tự.",
        FILE_ACCT_FORMAT: "Tài khoản nhận chỉ bao gồm chữ hoặc/và số.",
        FILE_ACCT_NAME_FORMAT: "Tên tài khoản nhận không được chứa ký tự đặc biệt ngoại trừ ,.-+():\\_&?.",
        FILE_BANK_FORMAT: "Tên ngân hàng nhận không được chứa ký tự đặc biệt ngoại trừ ,.-+():\\_&?.",
        FILE_AMOUNT_BLANK: "Số tiền giao dịch không được để trống.",
        FILE_ACCT_BLANK: "Tài khoản nhận không được để trống.",
        FILE_BANK_BLANK: "Tên ngân hàng nhận không được để trống.",
        FILE_ACCT_LENGTH: "Tài khoản nhận không được vượt quá 25 ký tự.",
        FILE_ACCT_NAME_LENGTH: "Tên tài khoản nhận không được vượt quá 35 ký tự.",
        FILE_ACCT_NAME_BLANK: "Tên tài khoản nhận không được để trống.",
        FILE_AMOUNT_VALUE: "Số tiền giao dịch phải nằm trong khoảng từ 10,000 đ đến 499,999,999 đ.",
        FILE_BANK_LENGTH: "Tên ngân hàng nhận không được vượt quá 70 ký tự.",
        FILE_BANK_EXIST:
          "Ngân hàng nhận không thuộc danh sách hỗ trợ chuyển tiền lô. Bạn vui lòng nhập lại ngân hàng nhận theo danh sách trong file mẫu.",
      },
      title: {
        unknownError: "Lỗi không xác định",
        fileInValid: "File chuyển tiền không hợp lệ",
        fileNameInvalid: "Tên file không hợp lệ",
        noRegisterOtp: "Chưa đăng ký OTP",
        blockedSmartOtp: "Smart OTP bị khóa",
        inValidRowLabel: "Tên dòng không hợp lệ",
      },
    },
  },
};

export default en;
