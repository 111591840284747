import { createApi } from "@reduxjs/toolkit/query/react";
import { CONFIG_APP } from "@constants";
import { getSessionStorageItem } from "@utils";
import { baseQuery } from "@services/BulkTransferApp/baseQuery";

const auth = getSessionStorageItem(CONFIG_APP.AUTH_INFO);
const headers = {
  access_token: auth?.accessToken,
  user_id: auth?.userId,
  app_key: auth?.appKey,
  session_id: auth?.sessionId,
};

export const UploadFileApi = createApi({
  reducerPath: "uploadFileAPi",
  baseQuery,
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (body) => ({
        url: "upload",
        method: "POST",
        body,
      }),
    }),
    sendOtp: builder.mutation({
      query: (body) => ({
        url: "otp",
        method: "POST",
        body,
        headers,
      }),
    }),
    submitFile: builder.mutation({
      query: (body) => ({
        url: "submit",
        method: "POST",
        body,
        headers,
      }),
    }),
  }),
});
export const { useUploadFileMutation, useSendOtpMutation, useSubmitFileMutation } = UploadFileApi;
