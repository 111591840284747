import { createSlice } from "@reduxjs/toolkit";
import { CONFIG_APP } from "@constants";
import { getItem } from "@utils";

const language = getItem(CONFIG_APP.LANG);

const initialState = {
  currentLang: language ? JSON.parse(language) : "vi",
};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setCurrentLang: (state, { payload }) => {
      state.currentLang = payload;
    },
  },
});

export const { setCurrentLang } = settingSlice.actions;
export default settingSlice.reducer;
