import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "@services/Maintenance/baseQuery";

export const MaintenanceAPI = createApi({
  reducerPath: "MaintenanceAPI",
  baseQuery,
  endpoints: (builder) => ({
    getStatusMaintenance: builder.query({
      query: () => ({
        url: "/status",
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetStatusMaintenanceQuery } = MaintenanceAPI;
