import { createContext } from "react";

export const initialStateStep = {
  file: null,
  transactions: null,
  userName: "",
  verify: false,
  completed: false,
  purpose: null,
  isUploading: false,
  fileLocal: null,
  disabled: false,
  totalItems: 0,
  tran_id: null,
  dataCompleted: null,
  message: null,
  totalItemValid: null,
  totalAmountValid: null,
  tranMultiId: null,
};

const StepContext = createContext({});

export default StepContext;
