import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import packageJson from "../../../package.json";
import VNIcon from "@shared/icons/vn.png";
import EnglishIcon from "@shared/icons/english.svg";

import { setItem } from "@utils";
import { setCurrentLang } from "@redux/slices/settings";
import { CONFIG_APP, ENVIRONMENT, CURRENT_LANG } from "@constants";

const { Content } = Layout;

const RenderCurrentLanguage = ({ handleChange, language, icon }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      onClick={() => handleChange(language)}
    >
      <img src={icon} alt="icon" style={{ marginRight: 5 }} />
      <span>{t("common.currentLang")}</span>
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentLang } = useSelector((state) => state.settings);

  const handleChangeLanguage = useCallback(
    (value) => {
      let paths = window.location.pathname;
      const search = window.location.search;
      paths = paths.endsWith("/") ? paths.substring(0, paths.lastIndexOf("/")) : paths;
      const lastText = paths.split("/")[paths.split("/").length - 1];
      const isMatch = [CURRENT_LANG.VI, CURRENT_LANG.EN].includes(lastText);

      if (isMatch) {
        const newPath = paths.substring(0, paths.lastIndexOf("/") + 1) + value + search;
        navigate(newPath, { replace: true });
      } else {
        const newPath = paths.endsWith("/") ? `${paths}${value}` : `${paths}/${value}` + search;
        navigate(newPath, { replace: true });
      }

      setItem(CONFIG_APP.LANG, value);
      dispatch(setCurrentLang(value));
    },
    [dispatch, navigate]
  );

  const language = useMemo(
    () => ({
      [CURRENT_LANG.EN]: (
        <RenderCurrentLanguage handleChange={handleChangeLanguage} language={CURRENT_LANG.VI} icon={VNIcon} />
      ),
      [CURRENT_LANG.VI]: (
        <RenderCurrentLanguage handleChange={handleChangeLanguage} language={CURRENT_LANG.EN} icon={EnglishIcon} />
      ),
    }),
    [handleChangeLanguage]
  );

  const version = process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.PROD ? `- v${packageJson.version}` : "";

  return (
    <div className="footer">
      <Content>
        <div className="footer__main">
          <Typography style={{ fontSize: 14 }}>{`${t("footer.copyRight")} ${version}`}</Typography>
          <div className="footer__locale">
            <div style={{ fontSize: 14, cursor: "pointer" }}>{language[currentLang]}</div>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default Footer;
