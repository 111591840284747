import React, { useEffect, useMemo, useState } from "react";
import { Input } from "antd";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.less";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { PATTERN } from "@constants";

const FloatInput = ({
  label,
  placeholder,
  type,
  initialValue,
  id,
  isLoading,
  onChange,
  endAdornmentPassword,
  autoComplete = "off",
  allowOnlyNumber,
  ...res
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  if (!placeholder) placeholder = label;

  const isOccupied = useMemo(() => focus || (value && value.length !== 0), [focus, value]);
  useEffect(() => {
    if (initialValue) {
      setFocus(true);
      setValue(initialValue);
    }
    if (!initialValue) {
      setValue("");
      setFocus(false);
    }
  }, [initialValue]);

  const labelClass = isOccupied ? "as-label" : "label as-placeholder";

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleOnChange = (event) => {
    if (allowOnlyNumber) {
      if (event.target.value.match(PATTERN.ALLOW_ONLY_NUMBER)) {
        setValue(event.target.value);
        onChange(event);
      }
    } else {
      setValue(event.target.value);
      onChange(event);
    }
  };

  return (
    <div
      className="floating-label"
      onBlur={() => {
        const getValue = document.getElementById(id)?.value;
        if (!getValue) setFocus(false);
      }}
      onFocus={() => setFocus(true)}
    >
      <Input
        autoComplete={autoComplete}
        className="floating-input"
        onChange={handleOnChange}
        type={!showPassword ? type : "text"}
        value={initialValue ? initialValue : ""}
        {...res}
        id={id}
      />
      {endAdornmentPassword && (
        <div className="floating-label__eye" onClick={handleClickShowPassword}>
          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </div>
      )}
      {isLoading && (
        <div className="input-loading">
          <LoadingOutlined />
        </div>
      )}

      <label className={labelClass}>{isOccupied ? label : placeholder}</label>
    </div>
  );
};

FloatInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  initialValue: PropTypes.any,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  id: PropTypes.string,
  endAdornmentPassword: PropTypes.bool,
  autoComplete: PropTypes.string,
  allowOnlyNumber: PropTypes.bool,
};

export default FloatInput;
