// configuration store

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import settingsReducer from "@redux/slices/settings";
import { UploadFileApi } from "@services/BulkTransferApp/UploadFileApi";
import { CacheUploadFileApi } from "@services/CacheFileBulkTransferApi/CacheUploadFileApi";
import { MaintenanceAPI } from "@services/Maintenance/MaintenanceApi";

const rootReducer = combineReducers({
  settings: settingsReducer,
  [UploadFileApi.reducerPath]: UploadFileApi.reducer,
  [CacheUploadFileApi.reducerPath]: CacheUploadFileApi.reducer,
  [MaintenanceAPI.reducerPath]: MaintenanceAPI.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(UploadFileApi.middleware)
      .concat(CacheUploadFileApi.middleware)
      .concat(MaintenanceAPI.middleware),
  devTools: process.env.REACT_APP_ENVIRONMENT === "dev",
});
