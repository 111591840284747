import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Modal, Typography } from "antd";
import React, { memo, useEffect, useState } from "react";

import { PATTERN } from "@constants";
import { minTwoDigits } from "@utils";
import { Button, FloatInput } from "@components";

import CloseIcon from "@shared/icons/close-modal.svg";

import "./styles.less";

const PurposeTransferModal = ({ open, onClose, onFinish }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);

  const timer = new Date();
  const currentDay = timer.getDate();
  const currentMonth = timer.getMonth() + 1;
  const currentYear = timer.getFullYear();

  const DEFAULT_VALUE = `${t("pages.bulkTransfer.defaultValuePurpose")} ${minTwoDigits(currentDay)}-${minTwoDigits(
    currentMonth
  )}-${currentYear}`;

  const onFieldsChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setIsDisabled(!!hasErrors);
  };

  const handleClose = () => {
    form.resetFields();
    setIsDisabled(false);
    onClose();
  };

  useEffect(() => {
    form.setFieldValue("content", DEFAULT_VALUE);
  }, [t, form, DEFAULT_VALUE, open]);

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      keyboard={false}
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
      cancelButtonProps={{
        style: {
          display: "none",
        },
      }}
      width={600}
      closeIcon={<img src={CloseIcon} alt="icon" />}
      footer={[
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          key="buttons-verify"
        >
          <Button
            type="primary"
            className="modal__actions--ok"
            onClick={() => onFinish(form.getFieldValue("content"))}
            disabled={isDisabled}
          >
            {t("buttons.continue")}
          </Button>
        </div>,
      ]}
      centered
    >
      <div className="modal__purpose">
        <Typography className="title-modal">{t("pages.bulkTransfer.purposeTransfer")}</Typography>
        <Typography className="fieldNamePurpose" style={{ fontSize: 16 }}>
          {t("pages.bulkTransfer.fieldNamePurpose")}
        </Typography>

        <Form
          form={form}
          onFieldsChange={onFieldsChange}
          onFinish={() => onFinish(form.getFieldValue("content"))}
          initialValues={{ content: DEFAULT_VALUE }}
        >
          <Form.Item
            name="content"
            required
            rules={[
              {
                required: true,
                message: "",
              },
              {
                pattern: PATTERN.PURPOSE_TRANSFERS,
                message: "",
              },
            ]}
            style={{ marginBottom: 12 }}
          >
            <FloatInput
              autoComplete="off"
              maxLength={35}
              id="modal_reason"
              initialValue={form.getFieldValue("content")}
              label={t("inputs.form.bulkTransferPurpose")}
            />
          </Form.Item>
          <div className="transaction__notice">
            <Typography className="transaction__notice--title">{t("pages.bulkTransfer.notice")}:</Typography>
            <Typography>{t("pages.bulkTransfer.noticePurposePrompt2")}</Typography>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

PurposeTransferModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
};

export default memo(PurposeTransferModal);
