import React from "react";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "antd";
import PropTypes from "prop-types";
import "./styles.less";
import { useEffect } from "react";
import { useState } from "react";

const CustomPagination = (props) => {
  const { position = "end", totalItems, ...rest } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const handleOnchange = (value) => {
    searchParams.set("page", value);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setCurrentPage(searchParams.get("page") || 1);
  }, [searchParams]);

  return (
    <div className={`pagination-position-${position}`}>
      <Pagination
        onChange={handleOnchange}
        total={totalItems || 1}
        defaultCurrent={Number(currentPage) || 1}
        current={Number(currentPage) || 1}
        // prevIcon={<CaretLeftOutlined />}
        // nextIcon={<CaretRightOutlined />}
        {...rest}
      />
    </div>
  );
};
CustomPagination.propTypes = {
  position: PropTypes.string,
  totalItems: PropTypes.number,
};

export default CustomPagination;
