import { Tabs } from "antd";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState, useLayoutEffect } from "react";

import "./styles.less";

const TabsComponent = (props) => {
  const { itemsTabs, tabName, activeKey, centered } = props;
  const [selected, setSelected] = useState(itemsTabs[0].key);
  const [searchParams, setSearchParams] = useSearchParams();
  const onChange = (value) => {
    searchParams.set(tabName || "tab", value);
    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  useLayoutEffect(() => {
    const currentTab = searchParams.get(tabName || "tab");
    if (currentTab) {
      const check = itemsTabs.find((item) => item.key === Number(currentTab));
      if (check) setSelected(Number(currentTab));
    } else {
      setSelected(itemsTabs[0].key);
    }
  }, [searchParams, tabName, itemsTabs]);

  useEffect(() => {
    if (activeKey) setSelected(activeKey);
  }, [activeKey]);

  return (
    <div className={centered ? "tab-custom tab-center" : "tab-custom"}>
      <Tabs activeKey={Number(selected) || 0} onChange={onChange} items={itemsTabs} />
    </div>
  );
};
TabsComponent.propTypes = {
  itemsTabs: PropTypes.array,
  tabName: PropTypes.string,
  activeKey: PropTypes.number,
  centered: PropTypes.bool,
  container: PropTypes.bool,
};
export default TabsComponent;
