import PropTypes from "prop-types";
import { Modal, Typography } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { memo, React, useState, useEffect, useCallback } from "react";

import { useSteps } from "@hooks";
import { OtpInput, Button } from "@components";
import { encodePhoneNumber, generateFormData } from "@utils";
import { getSessionStorageItem } from "@utils/sessionStorage";
import { EXPIRED_SECONDS, CONFIG_APP, STATUS_CODE } from "@constants";

import { useSubmitFileMutation } from "@services/BulkTransferApp/UploadFileApi";
import { useCacheFileMutation } from "@services/CacheFileBulkTransferApi/CacheUploadFileApi";

import CloseIcon from "@shared/icons/close-modal.svg";

import "./styles.less";

const OtpInputModal = ({ open, onClose, mobile, userName, otpDefault }) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState([]);
  const [error, setError] = useState("");
  const [isResetTimer, setIsResetTimer] = useState(false);
  const [remainingTime, setRemainingTime] = useState(EXPIRED_SECONDS);

  const { currentLang } = useSelector((state) => state.settings);
  const { next, setSteps, steps } = useSteps();
  const [submitMutate, { isLoading }] = useSubmitFileMutation();
  const [cacheFileMutate] = useCacheFileMutation();

  const handleModalClose = useCallback(() => {
    onClose();
    setOtp([]);
    setRemainingTime(EXPIRED_SECONDS);
  }, [onClose]);

  useEffect(() => {
    if (open) {
      let timerId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timerId);
            handleModalClose();
            return 0;
          } else return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timerId);
    } else {
      setIsResetTimer(false);
      setRemainingTime(EXPIRED_SECONDS);
      setError("");
    }
  }, [open, handleModalClose]);

  const handleClick = () => {
    const auth = getSessionStorageItem(CONFIG_APP.AUTH_INFO);
    const payload = {
      tran_id: steps.tran_id,
      otp: otp.join(""),
      tran_multi_desc: steps.purpose,
      lang: currentLang.toUpperCase(),
      user_name: userName,
      session: auth.sessionId,
    };

    submitMutate(payload)
      .unwrap()
      .then((response) => {
        const { data, code, message } = response;
        if (code !== STATUS_CODE.SUCCESS) return setError(message.text);
        const payloadHeader = {
          user_id: data.user_id,
          app_key: data.app_key,
          session_id: auth.sessionId,
          access_token: data.access_token,
        };
        setSteps({ ...steps, dataCompleted: data, verify: true, tranMultiId: data.tran_multi_id });
        const { formData } = generateFormData({ file: steps.fileLocal, user_name: userName });
        cacheFileMutate({ body: formData, payloadHeader });
        next();
      })
      .catch(() => setError(t("messages.error.unknownError")));
  };

  const onChange = useCallback((otp) => {
    setOtp(otp);
  }, []);

  let encryptMobile = encodePhoneNumber(mobile);

  return (
    <>
      <Modal
        open={open}
        onCancel={handleModalClose}
        keyboard={false}
        maskClosable={false}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        closeIcon={<img src={CloseIcon} alt="icon" />}
        footer={[
          <div style={{ display: "flex", justifyContent: "flex-end" }} key="button-verify">
            <Button type="primary" onClick={handleClick} disabled={otp.filter((item) => item).length < 6 || isLoading}>
              {isLoading && <LoadingOutlined />} {t("buttons.verify")}
            </Button>
          </div>,
        ]}
        centered
      >
        <div className="modal__otp">
          <Typography className="title-modal">{t("pages.bulkTransfer.enterOtpCode")}</Typography>
          <Typography>
            {t("pages.bulkTransfer.otpHasBeenSend", {
              phone: encryptMobile,
            })}
          </Typography>
          <OtpInput onChange={onChange} isReset={isResetTimer} otpDefault={otpDefault} />
          {error && <Typography className="modal__otp-error">{error}</Typography>}
          <Typography>
            {t("pages.bulkTransfer.expiredOtpIn")}{" "}
            <span style={{ fontWeight: 700 }}>{`${t("pages.bulkTransfer.second", {
              remainingTime,
            })}`}</span>
          </Typography>
        </div>
      </Modal>
    </>
  );
};

OtpInputModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  mobile: PropTypes.string,
  userName: PropTypes.string,
  otpDefault: PropTypes.any,
};

export default memo(OtpInputModal);
