import { Input } from "antd";
import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import "./styles.less";


let currentOptIndex;

const OtpInput = ({ onChange, isReset, otpDefault }) => {
    const inputRef = useRef();
    const [otp, setOtp] = useState(otpDefault || new Array(6).fill(""));
    const [activeOtpIndex, setActiveOtpIndex] = useState(0);


    useEffect(() => {
        inputRef.current?.focus();
    }, [activeOtpIndex]);

    useEffect(() => {
        onChange(otp);
    }, [otp, onChange])

    useEffect(() => {
        if (isReset) {
            setOtp(new Array(6).fill(""));
            setActiveOtpIndex(0)
        }
    }, [isReset])

    useEffect(() => {
        if (otpDefault) {
            setOtp(otpDefault);
            setActiveOtpIndex(5)
        }
    }, [otpDefault])

    const handleOnchange = (event) => {
        const { value } = event.target;
        const newOtp = [...otp];
        if (Number(value) >= 0) {
            newOtp[currentOptIndex] = value.substring(value.length - 1);
            if (!value) setActiveOtpIndex(currentOptIndex - 1);
            else setActiveOtpIndex(currentOptIndex + 1);

            setOtp(newOtp);
        }
    };

    const handleOnKeyDown = (event, index) => {
        const { key } = event;
        currentOptIndex = index
        if (key === "Backspace") setActiveOtpIndex(currentOptIndex - 1);
    };

    return (
        <div className="otp">
            {otp.map((_, index) => {
                return (
                    <Input
                        key={`otp-${index}`}
                        ref={index === activeOtpIndex ? inputRef : null}
                        value={otp[index]}
                        type="number"
                        className="otp__input"
                        onKeyDown={(event) => handleOnKeyDown(event, index)}
                        onChange={handleOnchange}
                    />

                );
            })}
        </div>
    );
}
OtpInput.propTypes = {
    onChange: PropTypes.func,
    isReset: PropTypes.bool,
    otpDefault: PropTypes.any
}

export default memo(OtpInput);
