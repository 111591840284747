import { Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useImperativeHandle, useState } from "react";

import { ROUTES } from "@routes";
import { useModal, useSteps } from "@hooks";
import { Button, IconModal } from "@components";
import { initialStateStep } from "contexts/StepContext";

import CloseIcon from "@shared/icons/close-modal.svg";

const ModalComponent = React.forwardRef((props, ref) => {
  const { icon, success, error, footer = true, isProcessing, width = 400, ...rest } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSteps } = useSteps();
  const { isOpen, handleOpen, handleClose } = useModal();

  const [data, setData] = useState(null);

  const confirm = (values) => {
    setData(values);
    handleOpen();
  };

  useImperativeHandle(ref, () => ({
    confirm,
    handleClose,
  }));

  const onCancel = () => {
    if (data?.shouldResetTab) {
      setSteps(initialStateStep);
      navigate(ROUTES.HOME);
    }
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleClose}
      onCancel={onCancel}
      width={width}
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
      cancelButtonProps={{
        style: {
          display: "none",
        },
      }}
      closeIcon={<img src={CloseIcon} alt="icon" />}
      footer={
        footer
          ? [
              <div
                style={{
                  padding: "5px 0",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                key="button-verify"
              >
                <Button size="medium" disabled={isProcessing} onClick={handleClose}>
                  {t("buttons.cancel")}
                </Button>
                <Button
                  disabled={isProcessing}
                  style={{ marginLeft: 10 }}
                  type="primary"
                  size="medium"
                  onClick={data?.action}
                >
                  {isProcessing && <LoadingOutlined />} {data?.btnText || t("buttons.accept")}
                </Button>
              </div>,
            ]
          : false
      }
      className="modal-confirm--custom"
      centered
      {...rest}
    >
      <div className="modal__content">
        <IconModal icon={icon} success={success} error={error} />
        <div>
          {!!data?.title && (
            <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 16 }}>{data.title}</Typography>
          )}
          {!!data?.prompt && <Typography>{data.prompt}</Typography>}
        </div>
      </div>
    </Modal>
  );
});

export default ModalComponent;
