import { Layout } from "@components/Layout";
import React from "react";
import MaintenanceImage from "@shared/images/maintenance.png";
import { Typography } from "antd";
import "./styles.less";
import { useTranslation } from "react-i18next";

const Maintenance = () => {
  const { t: translate } = useTranslation();
  return (
    <Layout isNotShowBreadcrumb>
      <div className="main">
        <div className="maintenance">
          <img src={MaintenanceImage} alt="" />
          <Typography className="maintenance__title">{translate("pages.maintenance.system")}</Typography>
          <Typography className="maintenance__sub">{translate("pages.maintenance.tryAgain")}</Typography>
        </div>
      </div>
    </Layout>
  );
};

export default Maintenance;
