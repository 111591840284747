import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { ENVIRONMENT } from "@constants";

const environment = process.env.REACT_APP_ENVIRONMENT;

const BASE_WEB_HOST =
  environment === ENVIRONMENT.PROD || environment === ENVIRONMENT.PREPROD
    ? window.location.origin
    : `${window.location.origin}/${environment}`;

export const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_WEB_HOST}/myvib-transfer-webapi/v1/api/system`,
});
