import PropTypes from "prop-types";
import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import ExclamationMark from "@shared/icons/exclamation-mark.svg";

const IconModal = ({ icon, success, error }) => {
  return (
    <div className={`background-icon ${success ? "success" : "error"}`}>
      {success && <CheckOutlined style={{ fontSize: "18px", color: "#27A87A" }} />}
      {error && <img src={ExclamationMark} alt="error-icon" />}
    </div>
  );
};

IconModal.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.bool,
};
export default IconModal;
