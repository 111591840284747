import { Button } from "@components/Button";
import { Layout } from "@components/Layout";
import { ROUTES } from "@routes/index";
import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./styles.less";

const NotFound = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const handleRedirectHome = () => {
    navigate(`${ROUTES.HOME}`);
  };

  return (
    <Layout isNotShowBreadcrumb>
      <div className="main">
        <div className="not-found">
          <Typography>404</Typography>
          <Typography className="not-found__title">{translate("pages.notFound.pageNotFound")}</Typography>
          <Typography className="not-found__sub">{translate("pages.notFound.pageYouSearch")}</Typography>
          <Button onClick={handleRedirectHome} type="primary">
            {translate("buttons.goToUploadFile")}
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
