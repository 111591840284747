import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import LogoBlue from "@shared/images/logo-blue.svg";
import { useSelector } from "react-redux";
import { CURRENT_LANG } from "@constants";

const Header = ({ isNotShowBreadcrumb }) => {
  const { t } = useTranslation();
  const { currentLang } = useSelector((state) => state.settings);

  const handleClick = () => {
    if (currentLang === CURRENT_LANG.EN) return window.location.replace("https://www.vib.com.vn/en/home");
    window.location.replace("https://www.vib.com.vn/vn/home");
  };

  return (
    <div className={"header-layout"} style={{ minHeight: 72 }}>
      <Row className="header-admin-conts" align="middle">
        <Col span={8} className="user-name">
          {!isNotShowBreadcrumb && (
            <Breadcrumb>
              <Breadcrumb.Item>{t("breadcrumb.bulkTransfer")}</Breadcrumb.Item>
            </Breadcrumb>
          )}
        </Col>
        <Col xs={24} md={8} lg={8} sm={8}>
          <div className="logo-admin">
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
              <img src={LogoBlue} width="70" alt="logo" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
Header.propTypes = {
  isShowBreadcrumb: PropTypes.bool,
};

export default Header;
