import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { ENVIRONMENT } from "@constants";

const environment = process.env.REACT_APP_ENVIRONMENT;
const myVibApiDomain = process.env.REACT_APP_MYVIB2_API_DOMAIN.startsWith("https")
  ? process.env.REACT_APP_MYVIB2_API_DOMAIN
  : null;

const BASE_MYVIB2_API_DOMAIN = myVibApiDomain
  ? myVibApiDomain
  : environment === ENVIRONMENT.PROD || environment === ENVIRONMENT.PREPROD
  ? "https://mapi.vib.com.vn"
  : "https://nonprod-mapi.vib.com.vn";

export const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_MYVIB2_API_DOMAIN}/${environment}/myvib-customer-file/api/v1/`,
});
