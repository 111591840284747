import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "@services/CacheFileBulkTransferApi/baseQuery";

export const CacheUploadFileApi = createApi({
  reducerPath: "cacheFileApi",
  baseQuery,
  endpoints: (builder) => ({
    cacheFile: builder.mutation({
      query: ({ body, payloadHeader }) => ({
        url: "upload-file",
        method: "POST",
        body,
        headers: {
          user_id: payloadHeader?.user_id,
          app_key: payloadHeader?.app_key,
          session_id: payloadHeader?.session_id,
          access_token: payloadHeader?.access_token,
        },
      }),
    }),
  }),
});

export const { useCacheFileMutation } = CacheUploadFileApi;
