import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { Header, Footer } from "@components";
import { setItem } from "@utils/localStorage";
import { CONFIG_APP, CURRENT_LANG } from "@constants/app";
import { setCurrentLang } from "@redux/slices/settings";
import { useLazyGetStatusMaintenanceQuery } from "@services/Maintenance/MaintenanceApi";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@routes/index";

const LayoutAdmin = ({ isNotShowBreadcrumb, children, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentLang } = useSelector((state) => state.settings);
  const [getStatusMaintenance, { data: maintenanceResult }] = useLazyGetStatusMaintenanceQuery();

  useEffect(() => {
    const paths = window.location.pathname.split("/");
    const language = paths[paths.length - 1];
    const isMatch = [CURRENT_LANG.VI, CURRENT_LANG.EN].includes(language);

    if (!isMatch) {
      setItem(CONFIG_APP.LANG, currentLang);
      dispatch(setCurrentLang(currentLang));
    } else {
      setItem(CONFIG_APP.LANG, language);
      dispatch(setCurrentLang(language));
    }
  }, [dispatch, currentLang]);

  useEffect(() => {
    getStatusMaintenance();
  }, [getStatusMaintenance]);

  useEffect(() => {
    if (maintenanceResult?.data?.maintenance) return navigate(`${ROUTES.MAINTENANCE}/${currentLang}`);
  }, [maintenanceResult, navigate, currentLang]);

  return (
    <>
      <Layout className="my-vib-layout">
        <Header isNotShowBreadcrumb={isNotShowBreadcrumb} />
        {children}
        <Footer />
      </Layout>
    </>
  );
};

LayoutAdmin.propTypes = {
  isShowBreadcrumb: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
export default LayoutAdmin;
