import React from "react";
import { Spin } from 'antd';
import './styles.less'

const Backdrop = () => {
  return (
    <div className="backdrop">
      <Spin size="large" />
    </div>
  );
};

export default Backdrop;
