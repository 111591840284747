import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import "./styles.less";

const CustomButton = (props) => {
  const { size = "large", disabled, onClick, ...rest } = props;

  return (
    <div
      style={{ cursor: disabled ? "no-drop" : "pointer" }}
      onClick={disabled ? null : onClick}
      className="btn-custom"
    >
      <Button disabled={disabled} size={size} {...rest} />
    </div>
  );
};

CustomButton.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
export default CustomButton;
