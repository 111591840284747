/* eslint-disable no-extend-native */
import { CONFIG_APP, FILE_EXTENSION, PAGE_SIZE, PATTERN, TRAN_AMOUNT } from "@constants";

String.prototype.format = function () {
  var formatted = this;
  for (var arg in arguments) {
    formatted = formatted.replace("{" + arg + "}", arguments[arg]);
  }
  return formatted;
};

export const generateNumOrder = (index = 0, page = 1) => {
  const currentIndex = page > 1 ? (page - 1) * PAGE_SIZE + index : index;
  return minTwoDigits(currentIndex);
};

export const generateFormData = (data) => {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, typeof data[key] === "string" ? data[key]?.trim() : data[key]);
  }
  return { formData };
};

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const checkRequestSuccess = (response) => {
  let isValid = false;
  const { data, code, message } = response;
  if (!!data && code === CONFIG_APP.STATUS_CODE && message === CONFIG_APP.STATUS_CODE) isValid = true;
  return isValid;
};

export const getValueIsNotEqualOfTwoObj = (oldData, newData) => {
  let dataChange = {};
  for (const [key, value] of Object.entries(oldData)) {
    if (value !== newData[key]) {
      const merge = { [key]: newData[key] };
      dataChange = { ...dataChange, ...merge };
    }
  }
  return { dataChange };
};

export const isEmptyObj = (obj) => Object.keys(obj).length === 0;

export const checkFileSize = (size, fileSize = FILE_EXTENSION.SIZE) => {
  return size / 1024 / 1024 <= fileSize;
};

export const isValidDate = (date) => {
  // format date is DD/MM/YYYY
  const regex = new RegExp(PATTERN.FORMAT_DATE);
  return !!date?.trim()?.match(regex);
};

export const isValidLimitNumber = (value) => {
  const regex = /[0-9,.]/;
  return !!value?.trim()?.match(regex);
};

export const fileToDataUri = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

export const getFileExtension = (filename) => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

export const isExitsKeyOfObj = (object, key) => {
  // eslint-disable-next-line no-prototype-builtins
  return object.hasOwnProperty(key);
};

// eslint-disable-next-line no-unused-vars
export const maskCurrency = (value, maxLength = 12, radix = ",") => {
  const newValue = value?.toString()?.replaceAll(",", "");
  const [integer, decimal] = newValue ? newValue.split(".") : [""];

  return decimal
    ? `${integer.replace(/\B(?=(\d{3})+(?!\d))/g, radix)}.${decimal}`
    : integer.replace(/\B(?=(\d{3})+(?!\d))/g, radix);
};

export const encodePhoneNumber = (phone) => {
  if (phone) {
    return phone.replace(/(\d{3})(\d{4})(\d{3})/, "$1xxxx$3");
  }
  return "";
};

export const removeAccentsObject = (obj) => {
  let newObject = {};
  for (const [key, value] of Object.entries(obj)) {
    const desired = key.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    newObject = { ...newObject, [desired]: value };
  }

  return newObject;
};

export const checkAccountValid = (account) => {
  if (!account) return false;
  const regex = new RegExp(/^[a-zA-Z0-9]{1,25}$/);
  return !!account.match(regex);
};

export const checkOrderNumber = (value) => {
  let isValid = true;
  // num can blank
  // if has num then num includes 1 or 2 digital
  let regex = new RegExp(/^[\\.0-9 ]{0,2}$/);
  if (!value || !value.match(regex)) isValid = false;
  return isValid;
};

export const checkNameBanking = (banking) => {
  let isValid = true;
  let regex = new RegExp(PATTERN.BANKING_NAME);
  if (!banking.match(regex)) isValid = false;
  return isValid;
};

export const checkItemsDuplicated = (items = []) => {
  const temp = new Set();
  const result = [];
  items.forEach((item) => {
    let newItem = {
      ...item,
      banking: removeVietNameseDiacritics(item.banking).toUpperCase(),
      content: removeVietNameseDiacritics(item.content).toUpperCase(),
    };
    delete newItem.num;
    const hash = JSON.stringify(newItem);
    if (!temp.has(hash)) {
      temp.add(hash);
    } else {
      result.push(item);
    }
  });

  return result;
};

export const parserStringItemArray = (items = []) => {
  const accounts = items.map((item) => item.account_no);
  const uniq = [...new Set(accounts)];
  return uniq.join(", ");
};

export const checkEmpty = (value) => !!value;

export const checkContentValid = (content) => {
  const regex = new RegExp(PATTERN.TRAN_DESCRIPTION);
  return !!content?.match(regex);
};

export const checkAmountValid = (amount) => {
  let isValid = true;
  const parser = String(amount).split(",").join("").split(".").join("") * 1;

  const regex = new RegExp(PATTERN.FORMAT_AMOUNT);
  if (parser < TRAN_AMOUNT.MIN || parser > TRAN_AMOUNT.MAX || !amount.match(regex)) isValid = false;

  return isValid;
};

export const isValidRowLabelFile = (array = []) => {
  let isValid = true;
  array.forEach((item) => {
    if (!String(item.replace(/[\r\n]/g, ""))?.match(PATTERN.ROW_LABEL)) {
      isValid = false;
    }
  });
  return isValid;
};

export const SheetJSFT = FILE_EXTENSION.LIST_EXTENSION.map((item) => "." + item).join(",");

export const isValidFileExtension = (fileName) => {
  const getFileName = getFileExtension(fileName);
  const isValid = SheetJSFT.indexOf(getFileName);
  if (isValid > -1) return true;
  return false;
};

export const isValidFileName = (fileName) => {
  const name = fileName.split(".");
  name.splice(name.length - 1, 1);
  return !!name.join(".").match(PATTERN.FILE_NAME);
};

export const minTwoDigits = (value) => (value ? ("0" + value).slice(-2) : "");

export const removeVietNameseDiacritics = (str = "") => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const formatAmount = (number) => number.toLocaleString("en-US");
export const hasWhiteSpace = (s) => /\s/g.test(s);
