import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "@routes";
import { Backdrop } from "@components";
import { AppRoutes } from "@routes/AppRoutes";

const Router = () => {
  const { i18n } = useTranslation();
  const { currentLang } = useSelector((state) => state.settings);

  const ResolveRoutes = () =>
    AppRoutes.map((route, index) => (
      <Route key={`routes-${index}`} path={`${route.path}`}>
        <Route path={`${route.path}`} element={route.element} />
      </Route>
    ));

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang, i18n]);
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Backdrop />}>
          <Routes>
            {ResolveRoutes()}
            <Route path="/" element={<Navigate to={`${ROUTES.HOME}`} />} />
            <Route path="*" element={<Navigate to={`${ROUTES.NOT_FOUND}`} />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export { Router };
