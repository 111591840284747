import PropTypes from "prop-types";
import { Modal, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useState, useImperativeHandle } from "react";
import { useModal } from "@hooks";
import { Button } from "@components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@routes/index";

const ConfirmModal = React.forwardRef((props, ref) => {
  const { isProcessing, isUpdate, hiddenBtnCancel, zIndex = 990 } = props;
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { isOpen, handleOpen, handleClose } = useModal();
  const [data, setData] = useState(null);

  const confirm = (values) => {
    setData(values);
    handleOpen();
  };

  useImperativeHandle(ref, () => ({
    confirm,
    handleClose,
  }));


  const onCancel = () => {
    handleClose()
    data?.shouldResetTab && navigate(ROUTES.HOME)
  }

  return (
    <div className="modal-confirm">
      <Modal
        open={isOpen}
        onOk={onCancel}
        onCancel={onCancel}
        footer={false}
        width={400}
        className="modal-confirm--custom"
        centered
        zIndex={zIndex}
        closable={false}
      >
        <div className="modal__content">
          {!!data?.title && <div className="modal__title">{data.title}</div>}
          {!!data?.prompt && <Typography className="modal__prompt">{data.prompt}</Typography>}

          <div className="modal__actions">
            {!isUpdate && !hiddenBtnCancel && (
              <Button disabled={isProcessing} onClick={handleClose} className="modal__actions--cancel">
                {t("buttons.cancel")}
              </Button>
            )}

            <Button onClick={data?.action} type="primary" className="modal__actions--ok" disabled={isProcessing}>
              {isProcessing && <LoadingOutlined />}
              {data?.btnText || t("buttons.accept")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

ConfirmModal.propTypes = {
  isProcessing: PropTypes.bool,
  isUpdate: PropTypes.bool,
  hiddenBtnCancel: PropTypes.bool,
  zIndex: PropTypes.number,
};

export default ConfirmModal;
ConfirmModal.displayName = "ConfirmModal";
