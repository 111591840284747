import React from "react";
import { ROUTES } from ".";
import { LazyBulkTransferPage, LazyHealthyPage } from "@pages";
import NotFound from "@pages/NotFound";
import Maintenance from "@pages/Maintenance";

export const PagesRoutes = [
  // COMPONENTS
  {
    path: ROUTES.HOME,
    element: <LazyBulkTransferPage />,
    layout: "PrivateRouter",
  },
  {
    path: ROUTES.HOME_EN,
    element: <LazyBulkTransferPage />,
    layout: "PrivateRouter",
  },
  {
    path: ROUTES.HOME_VI,
    element: <LazyBulkTransferPage />,
    layout: "PrivateRouter",
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <NotFound />,
  },
  {
    path: ROUTES.NOT_FOUND_VI,
    element: <NotFound />,
  },
  {
    path: ROUTES.NOT_FOUND_EN,
    element: <NotFound />,
  },
  {
    path: ROUTES.MAINTENANCE,
    element: <Maintenance />,
  },
  {
    path: ROUTES.MAINTENANCE_VI,
    element: <Maintenance />,
  },
  {
    path: ROUTES.MAINTENANCE_EN,
    element: <Maintenance />,
  },
  {
    path: ROUTES.HEALTH,
    element: <LazyHealthyPage />,
  },
];
